import {Component, OnInit} from "@angular/core";
import {ContactService} from '@app/modules/contactez-nous/services/contact.service';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {ConnexionService} from '../connexion/services/connexion.service';
import * as moment from 'moment';

declare let $: any;

@Component({
  selector: "app-member-detail",
  templateUrl: "./member-detail.component.html",
  styleUrls: ["./member-detail.component.css"],
})
export class MemberDetailComponent implements OnInit {
  _edit: boolean = true;
  public gestionnaire: any = {};
  public contact1: any = {};
  public contact2: any = {};
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  phonePatern = /^((\\+91-?)|0)?[0-9]{9}$/;
  zipCodePatern = /^[0-9]{5}(?:-[0-9]{5})?$/;
  associationForm: FormGroup;
  members = null;
  datas = null;
  owners = null;
  public role;
  keyword: any
  departements = [
    "Ain", "Aisne", "Allier", "Alpes-de-Haute-Provence", "Hautes-Alpes", "Alpes-Maritimes",
    "Ardèche", "Ardennes", "Ariège", "Aube", "Aude", "Aveyron", "Bouches-du-Rhône", "Calvados",
    "Cantal", "Charente", "Charente-Maritime", "Cher", "Corrèze", "Corse-du-Sud", "Haute-Corse",
    "Côte-d'Or", "Côtes d'Armor", "Creuse", "Dordogne", "Doubs", "Drôme", "Eure", "Eure-et-Loir",
    "Finistère", "Gard", "Haute-Garonne", "Gers", "Gironde", "Hérault", "Ille-et-Vilaine", "Indre",
    "Indre-et-Loire", "Isère", "Jura", "Landes", "Loir-et-Cher", "Loire", "Haute-Loire", "Loire-Atlantique",
    "Loiret", "Lot", "Lot-et-Garonne", "Lozère", "Maine-et-Loire", "Manche", "Marne", "Haute-Marne", "Mayenne",
    "Meurthe-et-Moselle", "Meuse", "Morbihan", "Moselle", "Nièvre", "Nord", "Oise", "Orne", "Pas-de-Calais",
    "Puy-de-Dôme", "Pyrénées-Atlantiques", "Hautes-Pyrénées", "Pyrénées-Orientales", "Bas-Rhin", "Haut-Rhin",
    "Rhône", "Haute-Saône", "Saône-et-Loire", "Sarthe", "Savoie", "Haute-Savoie", "Paris", "Seine-Maritime",
    "Seine-et-Marne", "Yvelines", "Deux-Sèvres", "Somme", "Tarn", "Tarn-et-Garonne", "Var", "Vaucluse",
    "Vandée", "Vienne", "Haute-Vienne", "Vosges", "Yonne", "Territoire de Belfort", "Essonne",
    "Hauts-de-Seine", "Seine-St-Denis", "Val-de-Marne", "Val-D'Oise"
  ]
  public detailId;
  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    private router: Router,
    private serviceNotif: NotificationsService,
    private fb: FormBuilder,
    private connectionService: ConnexionService,
  ) {
    this.associationForm = this.groupeFormBuiler();
  }

  private groupeFormBuiler(): FormGroup {
    return this.fb.group({
      'nom': new FormControl(''),
      'adresse': new FormControl(''),
      'ville': new FormControl(''),
      'status': new FormControl(''),
      'isSignupFeePaid': new FormControl(''),
      'code': new FormControl('', [
        Validators.pattern(this.zipCodePatern),
      ]),
      'telephone': new FormControl('', [
        Validators.pattern(this.phonePatern),
      ]),
      'email': new FormControl('', [
        Validators.pattern(this.emailPattern),
      ]),
      'department': new FormControl(''),
      'lofSignOutDate': new FormControl({value: null, disabled: true},
        {updateOn: 'change'}
      ),

      'gestionnaireLastName': new FormControl(''),
      'gestionnaireFirstName': new FormControl(''),
      'gestionnaireFunction': new FormControl(''),
      'gestionnaireCity': new FormControl(''),
      'gestionnairePhone': new FormControl(''),
      'gestionnaireEmail': new FormControl(''),

      'contact1LastName': new FormControl(''),
      'contact1FirstName': new FormControl(''),
      'contact1Function': new FormControl(''),
      'contact1City': new FormControl(''),
      'contact1Phone': new FormControl(''),
      'contact1Email': new FormControl(''),

      'contact2LastName': new FormControl(''),
      'contact2FirstName': new FormControl(''),
      'contact2Function': new FormControl(''),
      'contact2City': new FormControl(''),
      'contact2Phone': new FormControl(''),
      'contact2Email': new FormControl(''),
      'activatedDate': new FormControl({value: null, disabled: true}),
      'rnaNumber': new FormControl('')
    });
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.getCurrentMembersAssociation();
      this.getCurrentUser();
    }
    let id = this.route.snapshot.paramMap.get("id");
    this.detailId = id;
    this.getOwnerAssociationGroup();
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
    })
  }

  public getCurrentMembersAssociation() {
    let id = this.route.snapshot.paramMap.get("id");
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          this.datas = res.data;
        this.getDataOwner(res.data.owners);
        this.updateMemberInfoValue(this.datas);
        this.updateMemberGestionnaire(this.gestionnaire);
        this.updateMemberContact1(this.contact1);
        this.updateMemberContact2(this.contact2);
          if (res) {
            this.members = res.data.id;
            this.contactService.getCurrentUserService().subscribe((item: any) => {
              this.role = item.data.role.value;
              if (res.data.lofId !== id || item.data.role.value === "USER") {
                if ((item.data.role.value === "ASSOCIATION_MANAGER")) {
                  this.router.navigate(['home']);
                }
              }
            })
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
            return this.members;
          }
        }
      );
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    const input = {
      "name": this.associationForm.get('nom').value || this.datas.name,
      "address": this.associationForm.get('adresse').value || this.datas.address,
      "city": this.associationForm.get('ville').value || this.datas.city,
      // LOF-579 update RNA value
      "rnaNumber": this.associationForm.get('rnaNumber').value || this.datas.rnaNumber,
      // "status": ((this.associationForm.get('status').value === "true") ? true :false) || Boolean(this.datas.enabled),
      "isSignupFeePaid": this.associationForm.get('isSignupFeePaid').value || this.datas.isSignupFeePaid,
      "lofSignOutDate": this.getLofSignOutDate(),
      "postalCode": this.associationForm.get('code').value || this.datas.postalCode,
      "phone": this.associationForm.get('telephone').value || this.datas.phone,
      "email": this.associationForm.get('email').value || this.datas.email,
      "department": this.associationForm.get('department').value || this.datas.department,
      "owners": [
        {
            "id": this.gestionnaire.id,
            "firstName": this.associationForm.get('gestionnaireFirstName').value || this.gestionnaire.firstName,
            "lastName": this.associationForm.get('gestionnaireLastName').value || this.gestionnaire.lastName,
            "email": this.associationForm.get('gestionnaireEmail').value || this.gestionnaire.email,
            "phone": this.associationForm.get('gestionnairePhone').value || this.gestionnaire.phone,
            "city": this.associationForm.get('gestionnaireCity').value || this.gestionnaire.city,
            "function": this.associationForm.get('gestionnaireFunction').value || this.gestionnaire.function
        },
        {
          "id": this.contact1.id,
          "firstName": this.associationForm.get('contact1FirstName').value || this.contact1.firstName,
          "lastName": this.associationForm.get('contact1LastName').value || this.contact1.lastName,
          "email": this.associationForm.get('contact1Email').value || this.contact1.email,
          "phone": this.associationForm.get('contact1Phone').value || this.contact1.phone,
          "city": this.associationForm.get('contact1City').value || this.contact1.city,
          "function": this.associationForm.get('contact1Function').value || this.contact1.function
        },
        {
          "id": this.contact2.id,
          "firstName": this.associationForm.get('contact2FirstName').value || this.contact2.firstName,
          "lastName": this.associationForm.get('contact2LastName').value || this.contact2.lastName,
          "email": this.associationForm.get('contact2Email').value || this.contact2.email,
          "phone": this.associationForm.get('contact2Phone').value || this.contact2.phone,
          "city": this.associationForm.get('contact2City').value || this.contact2.city,
          "function": this.associationForm.get('contact2Function').value || this.contact2.function
        }
      ],
      "activatedDate": this.associationForm.get('activatedDate') && this.associationForm.get('activatedDate').value
    };

    if (!this.contactService.checkIfSameMail(input.owners[0].email,input.owners[1].email, input.owners[2].email)) {
      this.contactService.updateAssociationService(input, this.datas.lofId).subscribe(
        (res:any) => {
          this.serviceNotif.success('Succès', "Modification réussie !", {
            position: ['top', 'right'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
          this.edit = true;
          this.datas = res.data;
          this.updateMemberInfoValue(this.datas);
          this.getDataOwner(this.datas.owners);
          this.updateMemberGestionnaire(this.gestionnaire); // this.datas.owners[0]
          this.updateMemberContact1(this.contact1); // this.datas.owners[1]
          this.updateMemberContact2(this.contact2); // this.datas.owners[2]
        },
        error => {
          this.serviceNotif.error('Error', `Les 3 e-mails du gestionnaire, du 2e contact et du 3e contact ne doivent pas être identiques`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
          console.log("error send register -> ", error);
        }
      )
    }
  }

  private getLofSignOutDate(): Date {
    const v = this.associationForm.get('lofSignOutDate') && this.associationForm.get('lofSignOutDate').value;
    return v ? new Date(v) : null;
  }

  public getDataOwner(dataAssociation: any[]) {
    const sortedFilter = dataAssociation.sort((a, b) => a.position - b.position)
    this.gestionnaire = sortedFilter[0]
    this.contact1 = sortedFilter[1]
    this.contact2 = sortedFilter[2]
  }


  public getOwnerAssociationGroup() {
    const memberId = this.route.snapshot.paramMap.get("id");
    this.contactService
      .getByAssociationService(memberId)
      .subscribe((res: any) => {
        if (res) {
          this.getDataOwner(res.data.owners);
          //????
          this.datas = res.data;
          this.updateMemberInfoValue(this.datas);
          this.updateMemberGestionnaire(this.gestionnaire);
          this.updateMemberContact1(this.contact1);
          this.updateMemberContact2(this.contact2);
          //value="{{datas.activatedDate | date: 'dd/MM/yyyy'}}"
          if (this.datas && this.datas.activatedDate){
            this.associationForm.get('activatedDate').patchValue(this.datas.activatedDate);
          } else {
            this.associationForm.get('activatedDate').patchValue(null);
          }
          if (this.datas && this.datas.lofSignOutDate) {
            this.associationForm.get('lofSignOutDate').patchValue(this._formatFormDate(this.datas.lofSignOutDate));
          } else {
            this.associationForm.get('lofSignOutDate').patchValue(null);
          }
        }
      },
      error => {
        this.router.navigate(['home'])
      });
  }

  deleteMember() {
    this.contactService.deleteAssociationService(this.datas.lofId).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['group_asso_list'])
      }
    },
      error => {
        this.serviceNotif.error('Error', "Echec de suppression !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      });
  }

  set edit(v: boolean) {
    this._edit = v;
    //FIXME: Grave, why edit is true for readonly. Big mess
    if (!this.isEditEnabled() && this.adminGrantee()) {
      this.associationForm.get('activatedDate').enable();
      this.associationForm.get('lofSignOutDate').enable();
    } else {
      this.associationForm.get('activatedDate').disable();
      this.associationForm.get('lofSignOutDate').disable();
    }
  }
  get edit() {
    return this._edit;
  }
  readOnly() {
    this.edit = !this.edit;
  }
  get f() { return this.associationForm.controls; }
  get nom() { return this.associationForm.get('nom'); }
  get adresse() { return this.associationForm.get('adresse'); }
  get ville() { return this.associationForm.get('ville'); }
  // get rnaNumber() { return this.associationForm.get('rnaNumber'); }
  get status() { return this.associationForm.get('status'); }
  get isSignupFeePaid() { return this.associationForm.get('isSignupFeePaid'); }
  get code() { return this.associationForm.get('code'); }
  get telephone() { return this.associationForm.get('telephone'); }
  get email() { return this.associationForm.get('email'); }
  get department() { return this.associationForm.get('department'); }

  get gestionnaireLastName() { return this.associationForm.get('gestionnaireLastName'); }
  get gestionnaireFirstName() { return this.associationForm.get('gestionnaireFirstName'); }
  get gestionnaireFunction() { return this.associationForm.get('gestionnaireFunction'); }
  get gestionnaireCity() { return this.associationForm.get('gestionnaireCity'); }
  get gestionnairePhone() { return this.associationForm.get('gestionnairePhone'); }
  get gestionnaireEmail() { return this.associationForm.get('gestionnaireEmail'); }

  get contact1LastName() { return this.associationForm.get('contact1LastName'); }
  get contact1FirstName() { return this.associationForm.get('contact1FirstName'); }
  get contact1Function() { return this.associationForm.get('contact1Function'); }
  get contact1City() { return this.associationForm.get('contact1City'); }
  get contact1Phone() { return this.associationForm.get('contact1Phone'); }
  get contact1Email() { return this.associationForm.get('contact1Email'); }

  get contact2LastName() { return this.associationForm.get('contact2LastName'); }
  get contact2FirstName() { return this.associationForm.get('contact2FirstName'); }
  get contact2Function() { return this.associationForm.get('contact2Function'); }
  get contact2City() { return this.associationForm.get('contact2City'); }
  get contact2Phone() { return this.associationForm.get('contact2Phone'); }
  get contact2Email() { return this.associationForm.get('contact2Email'); }

  onFocused(e) {

  }

  onChangeSearch(e) {

  }

  selectEvent(e) {
  }

  adminGrantee () {
    // Lof grantee as admin grantee, TODO: should be manager with user authorizations
    return this.role === 'LOF_ADMIN' || this.role === 'SUPER_ADMIN';
  }

  isEditEnabled() {
    return this.edit;
  }

  updateMemberGestionnaire(contact) {
    if (contact) {
      this.associationForm.get('gestionnaireFirstName').patchValue(contact.firstName);
      this.associationForm.get('gestionnaireLastName').patchValue(contact.lastName);
      this.associationForm.get('gestionnaireEmail').patchValue(contact.email);
      this.associationForm.get('gestionnairePhone').patchValue(contact.phone);
      this.associationForm.get('gestionnaireCity').patchValue(contact.city);
      this.associationForm.get('gestionnaireFunction').patchValue(contact.function);
    }
  }

  updateMemberContact1(contact) {
    if (contact) {
      this.associationForm.get('contact1FirstName').patchValue(contact.firstName);
      this.associationForm.get('contact1LastName').patchValue(contact.lastName);
      this.associationForm.get('contact1Email').patchValue(contact.email);
      this.associationForm.get('contact1Phone').patchValue(contact.phone);
      this.associationForm.get('contact1City').patchValue(contact.city);
      this.associationForm.get('contact1Function').patchValue(contact.function);
    }
  }

  updateMemberContact2(contact) {
    if (contact) {
      this.associationForm.get('contact2FirstName').patchValue(contact.firstName);
      this.associationForm.get('contact2LastName').patchValue(contact.lastName);
      this.associationForm.get('contact2Email').patchValue(contact.email);
      this.associationForm.get('contact2Phone').patchValue(contact.phone);
      this.associationForm.get('contact2City').patchValue(contact.city);
      this.associationForm.get('contact2Function').patchValue(contact.function);
    }
  }

  updateMemberInfoValue(memberData) {
    if (memberData) {
      this.associationForm.get('nom').patchValue(memberData.name);
      this.associationForm.get('adresse').patchValue(memberData.address);
      this.associationForm.get('ville').patchValue(memberData.city);
      this.associationForm.get('isSignupFeePaid').patchValue(memberData.isSignupFeePaid);
      this.associationForm.get('lofSignOutDate').patchValue(this._formatFormDate(memberData.lofSignOutDate));
      this.associationForm.get('code').patchValue(memberData.postalCode);
      this.associationForm.get('telephone').patchValue(memberData.phone);
      this.associationForm.get('email').patchValue(memberData.email);
      this.associationForm.get('department').patchValue(memberData.department);
      this.associationForm.get('activatedDate').patchValue(memberData.activatedDate || null);
    }
  }

  get lofSignOutInputDate(): Date {
    return this.datas.lofSignOutDate ? new Date (this.datas.lofSignOutDate) : null;
  }

  private _formatFormDate(d: string) {
    if (d) {
      try {
        //const formDate = moment(d, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY'); //2024-09-05T23:36:13.000Z
        return moment(d, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate().getTime(); //2024-09-05T23:36:13.000Z
      } catch (e) {
        console.error(e);
        return d;
      }
    }
    return null;
  }

  onLofSignOutDate() {
    console.log (`lof signout date: ${this.associationForm.get('lofSignOutDate').value}`);
  }

}
